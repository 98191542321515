import React, { Fragment, useEffect } from "react";
import $ from 'jquery';
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";


export const HomeKPI = ({ data }) => {
    useEffect(() => {
        $(document).ready(function () {
            var counted = 0;
            $(window).scroll(function () {
                try {
                    var oTop = $('#counter').offset().top - window.innerHeight;
                    if (counted == 0 && $(window).scrollTop() > oTop) {
                        $('.count').each(function () {
                            var $this = $(this),
                                countTo = $this.attr('data-count');
                            $({
                                countNum: $this.text()
                            }).animate({
                                countNum: countTo
                            },

                                {

                                    duration: 2000,
                                    easing: 'swing',
                                    step: function () {
                                        $this.text(Math.floor(this.countNum));
                                    },
                                    complete: function () {
                                        $this.text(this.countNum);
                                        //alert('finished');
                                    }

                                });
                        });
                        counted = 1;
                    }
                } catch {
                    // console.log("Error")
                }
            });
        });
    });

    return (
        <Fragment>

            <section id="counter" class="kpi">
                <div class="overlay"></div>
                <div class="container">
                    <div class="row">

                        {data && data.map((row, key) => {
                            return (
                                <Fragment>
                                    {row.list && row.list.map((prow, key) => {

                                        return (
                                            <Fragment>

                                                <div class="col-lg-3 col-md-6 col-sm-6">
                                                    <div class="text-center">
                                                        <img src={image_url + '' + prow.image} alt=""></img>
                                                        <h3 class="count percent" data-count={prow.description}></h3>
                                                        <p>{prow.title ? prow.title : ''}</p>
                                                    </div>
                                                </div>


                                            </Fragment>
                                        )
                                    })}

                                </Fragment>
                            )
                        })}

                    </div>
                </div>
            </section>

        </Fragment>
    )
}

