import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";


export const HowWeWork = ({data}) => {
    return (
        <Fragment>
            {data && data.map((row,key)=>{
                return(
                    <Fragment>

                        <section class="how-we-work">
                            <div class="overlay"></div>
                                <div class="container">
                                    <div class="title-white text-center">
                                        <h1>{row.listcontent.description}</h1>
                                    </div>
                                    <div class="row">
                                        
                                        {row.list && row.list.map((prow,key)=>{

                                            return(
                                                <Fragment>

                                                <div class="col-lg-3 col-md-6 col-sm-6">
                                                    <div class="how-we-work-single">
                                                        <div class="how-we-work-banner">
                                                            <img src={image_url+''+prow.image} alt=""></img>
                                                        </div>
                                                        <h3>{prow.title}</h3>
                                                        <p>{prow.description}</p>
                                                    </div>

                                                </div>


                                                </Fragment>
                                            )
                                        })}
                    
                                    </div>
                                </div>
                        </section>
                    </Fragment>
                )
            })}


        </Fragment>
    )
}

