import React from 'react';
import { NavLink } from 'react-router-dom';
const SideBar =({side_bar})=> {

    const {menu_data={}}=side_bar;
    return (
      <div className="left-side-menu mb-5">
        <h3>Explore {side_bar.sidebar_title}</h3>

        <div id="accordion">
                {
                    menu_data?
                        menu_data.map((row,key)=>{
                            return (
                                <div>

                                    {row.child.length ?<div key={key} id={"heading-"+key} >
                                        <h5 className="mb-0">
                                            
                                            <a className="collapsed" role="button"  data-toggle="collapse" href={"#collapse-"+key} aria-expanded="false" aria-controls={"#collapse-"+key} /* to={row.url} */ >
                                            {row.title}
                                            </a>
                                        </h5>
                                    </div>:<div key={key} >
                                        <h5 className="mb-0">
                                            
                                            <NavLink to={row.url}>{row.title}</NavLink>
                                        </h5>
                                    </div>}
                                    {row.child.length ?<div id={"collapse-"+key} data-parent="#accordion"  className="collapse" aria-labelledby={"heading-"+key}>
                                        <div className="card-body">
                                            <ul>
                                            {!row.child.length &&<li>
                                                <NavLink to={row.url}>{row.title}</NavLink>
                                            </li>}
                                            {
                                                row.child?
                                                    row.child.map((row_c,key_c)=>{
                                                    return (
                                                        <li key={key+key_c}>
                                                            <NavLink to={row_c.url}>{row_c.title}</NavLink>
                                                        </li>
                                                        )
                                                    })
                                                :
                                                <div className=""> </div>
                                            }
                                        </ul> 
                                    </div>
                                </div>:''}
            
                                </div>
                            )
                        }
                        )
                        :
                        <div className=""> </div>
                } 
        </div>
      </div>
    );
}

export default SideBar
