import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link,withRouter } from "react-router-dom";
import { getSetting, OurStoryData, PageContent, setPathLayout,CorporateSponsor } from "../actions/Utils";
import { image_url, host_name } from '../config/Api';
import Htmlcontent from '../components/Htmlcontent';
import BannerLeft from '../components/page/BannerLeft';
// import {CorporateSponsorItem} from '../components/corporate-sponsor/CorporateSponsorItem';

class AboutusLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path:'',
            header:{},
            container:{},
            footer:{},
            layout_id:'',
            page_content:{},
            our_story_data:{},
            corporate_sponsor_data:{},
            load_more:false,
            show_less:false
        };
    }
    componentDidMount = () => {
        // console.log('hello world');
         window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        

       
        const slug = this.props.match.path.replace('/','');

       // console.log(slug);

        this.props.PageContent(slug).then((res)=>{

        });

        this.props.OurStoryData().then((res)=>{
            this.setState({
                our_story_data:res
              })
        });

        this.props.CorporateSponsor().then((res)=>{
            this.setState({
                corporate_sponsor_data:res
              })
        });
    }

    componentDidUpdate= () => {
        

        // console.log('state ==',this.state.show_less);

        if(this.state.show_less && this.state.load_more){
            
            // console.log('update');
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });

        }else{

            console.log('scroll no');

            

        }

        // if(!load_more && !show_less){

        //     window.scroll({ top: 0, left: 0, behavior: 'smooth' });

        // }

        

    }

    LoadMoreOn = () => {
        this.setState({
            load_more: true,
            show_less: false,
            
        })

    }

    LoadLessOn = () => {
        this.setState({
            load_more: false,
            show_less: true,
            
        })

    }

    render() {
        const {page_data}=this.props.page_content.content;
        const {our_story_data,load_more,show_less} = this.state;
        const {corporate_sponsor_data} = this.state;
        const lang = this.props.lang;
        const { bread_curmbs } = this.props.page_content.content;

        console.log('page_data= ',page_data);

        // const {section}=this.props.page_content.content.section;
        var contents_top = [];
        var mission_vision_content = [];
        var mission_vision_content_image;
        var contents_bottom_list = [];
        var section_data_top = {};
        var about_mission_vision = {};
        var section_data_bottom_list = {};
        
        

        if(page_data){

            const {section} = page_data;
            console.log('sectiondata',section);
            if(section){
            section_data_top = section.find((row) => {

                if (row.name) {
                    if (row.name.toLowerCase() == 'about top content') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }

                else {
                    return false;
                }
            });

            

            if (section_data_top) {
                contents_top = section_data_top.contents[0]; //about top content load

            }
            
            // console.log('contents_top',contents_top);

            about_mission_vision = section.find((row) => {

                if (row.name) {
                    if (row.name.toLowerCase() == 'about mission and vision section') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }

                else {
                    return false;
                }
            });

            if (about_mission_vision) {
                mission_vision_content = about_mission_vision.contents[0].list; //top story load
                mission_vision_content_image = about_mission_vision.contents[0].listcontent.image;
                

            }

     

        } 
        }

        // console.log("contents_top =", contents_top);

        // console.log("mission_vision_content =", mission_vision_content.listcontent);
        // // console.log(mission_vision_content.length);

        // console.log("contents_bottom_list  =", contents_bottom_list);


            return (
                
                
                <Fragment>
                
                {/* <BannerLeft title="About" menu_name={''} banner={''}/> */}
               
                {page_data && <Fragment>

                {page_data.image && <Fragment> 
                {/* image load first */}

                {page_data.image && <Fragment>

                <section class="inner-header">
                    <img src={page_data.image ? image_url + page_data.image : `${host_name}img/innerbaner.png`} alt=""></img>
                    <h1>{page_data.title}</h1>
                    <div class="inner-breadcrumb">
                        <ul class="nav justify-content-center">
                            <li><Link to={{ pathname: `/home` }} >Home</Link></li>
                            <li>{page_data.title} </li>
                        </ul>
                    </div>
                    <div class="overlay"></div>
                </section>

                </Fragment> }

                {contents_top.description &&<Fragment>
                            <Htmlcontent data={contents_top.description} />
                </Fragment>}

                <section class="vision_mission">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="vision_mission_banner">
                                {mission_vision_content_image &&<Fragment>
                                    <img src={image_url+''+mission_vision_content_image} alt=""></img>
                                </Fragment>}
                                    
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="vision_mission_content">

                                { mission_vision_content.length > 0 ?
                                    mission_vision_content.map((row,key) => {

                                    return (
                                        <Fragment>

                                            <div className={`${key==0 ?  'mission-content mb-4' : 'mission-content'}`}>
                                                <div class="row">
                                                    <div class="col-md-2">
                                                        <img src={image_url+''+row.image} alt=""></img>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <h3>{row.title} </h3>
                                                        <p>
                                                            {row.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                    
                                    )
                                    })
                                    :
                                    ''
                                }
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="time-line">
                    <div class="title-black text-center">
                        <h1>Timeline</h1>
                    </div>
                    <div class="time-line-content">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="timeline1">

                                    { our_story_data.length > 0 && !load_more  ?
                                        our_story_data.slice(0,5).map((row) => {

                                        return (

                                            <Fragment>

                                            <div class="happening row">
                                                <div class="happening__period col-md-3">
                                                    <div class="wrapper rounded shadow-sm">
                                                        
                                                        {row.year &&<Fragment>
                                                            <h4 class="year">{row.year}</h4>
                                                        </Fragment>}
                                                    </div>
                                                </div>
                                                <div class="happening__desc col-md-8 offset-md-1">
                                                    {row.description &&<Fragment>
                                                        <Htmlcontent data={row.description} />
                                                    </Fragment>}
                                                   
                                                </div>
                                            </div>

                                        </Fragment>
                        
                                        )
                                        })
                                        
                                        :
                                        ''
                                    }

                                    { our_story_data.length > 0 && load_more  ?
                                        our_story_data.map((row) => {

                                        return (

                                            <Fragment>

                                            <div class="happening row">
                                                <div class="happening__period col-md-3">
                                                    <div class="wrapper rounded shadow-sm">
                                                        
                                                        {row.year &&<Fragment>
                                                            <h4 class="year">{row.year}</h4>
                                                        </Fragment>}
                                                    </div>
                                                </div>
                                                <div class="happening__desc col-md-8 offset-md-1">
                                                    {row.description &&<Fragment>
                                                        <Htmlcontent data={row.description} />
                                                    </Fragment>}
                                                   
                                                </div>
                                            </div>

                                        </Fragment>
                        
                                        )
                                        })
                                        
                                        :
                                        ''
                                    }

                                    
                                    {our_story_data.length > 5 && !load_more ? <Fragment>

                                        <button onClick={() => this.LoadMoreOn()} type="button" class="btn btn-primary m-auto d-block load_more">More</button>
                                    
                                        
                                    </Fragment> : ''}

                                    {our_story_data.length > 5 && load_more && !show_less ? <Fragment>

                                        <button onClick={() => this.LoadLessOn()} type="button" class="btn btn-primary m-auto d-block load_more">Less</button>


                                    </Fragment> : ''}

                                     

                                   
         
                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="corporate-sponsor mb-5">
                    <div class="title-black text-center">
                        <h1>Corporate Sponsors</h1>
                    </div>
                    <div class="corporate-sponsor-bg">
                        <div class="container">
                            <div class="corporate-sponsor-content">
                                <div class="row">

                                { corporate_sponsor_data.length > 0 ?
                                        corporate_sponsor_data.map((row3) => {

                                            return (
                                                
                                                <Fragment>
                                                    
                                                <div class="col-md-4">
                                                    <div class="corporate-sponsor-single">
                                                        
                                                    <Link to={{ pathname: `/corporate-sponsor/${row3.slug}` }}>
                                                        {row3.image && <img src={image_url + '' + row3.image} alt="" />}
                                                    </Link>
                                                    </div>
                                                </div>
                                                </Fragment>
         
                                            )
                                        
                                        
                                    })
                                    :
                                    ''
                                }
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                </Fragment> }

                </Fragment> }

            </Fragment>  
                
            )
        
   }
}

const mapStateToProps = state => ({
    page_content:state.contents.page_content,
    utils:state.utils,
    header:state.header,
    contents:state.contents,
    footer:state.footer,
    layout_id:state.utils.layout_id,
    routemenu:state.utils.routemenu,
    setting:state.utils.setting,
    lang:state.utils.lang
    
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
    setPathLayout:(requestData) =>dispatch(setPathLayout(requestData)),
    PageContent: (requestData) => dispatch(PageContent(requestData)),
    OurStoryData: (requestData) => dispatch(OurStoryData(requestData)),
    CorporateSponsor: (requestData) => dispatch(CorporateSponsor(requestData)),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AboutusLayout));