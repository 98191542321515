import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";


export const Ecosystem = ({data}) => {
    return (
        <Fragment>
            {data && data.map((row,key)=>{
                return(
                    <Fragment>
                        <section class="ecosystem">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="content-block-left">
                                            <h1>{row.listcontent.title}</h1>
                                            <p>
                                            {row.listcontent.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            
                                            {row.list && row.list.map((prow,key)=>{

                                                return(
                                                    <Fragment>

                                                        <div class="col-md-6">
                                                            <div class="ecosystem-single">
                                                                <div class="ecosystem-icon">
                                                                    <img src={image_url+''+prow.image} alt=""></img>
                                                                </div>
                                                                <h3>{prow.description}</h3>
                                                                <h4>{prow.title}</h4>
                                                            </div>
                                                        </div>


                                                    </Fragment>
                                                )
                                            })}
                        
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </section>

                        
                    </Fragment>
                )
            })}


        </Fragment>
    )
}

