import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link,withRouter } from "react-router-dom";
import { getSetting, NewsContent, PageContent, setPathLayout } from "../actions/Utils";


import BannerLeft from '../components/page/BannerLeft';
import { image_url, host_name } from '../config/Api';
import {ContentItem} from '../components/webcontent/ContentItem';



class WebContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path:'',
            header:{},
            container:{},
            page_content:{},
            footer:{},
            layout_id:'',
            news_content:{},
            is_filter:false,
            filter_loading:false,
            filter_data:[],
            userfilterdata:{}
        };
        this.textInput = React.createRef();
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const {location} = this.props.history;
        const {slug} = this.props.match.params;

        const slugpage = this.props.match.path.replace('/','');

       // console.log(slug);

        this.props.PageContent(slugpage).then((res)=>{

        });
        
        this.props.NewsContent(slug).then((res)=>{

        });
        
        
    }
    componentDidUpdate=(prevProps)=>
    {
        const {slug} = this.props.match.params;
        const {slug:prevSlug} = prevProps.match.params;

        if(prevSlug!=slug)
        {

            this.props.NewsContent(slug).then((res)=>{

            });
        }
    }

    Filter = (requestdata) => {
        console.log('requestdata = ',requestdata);
        this.setState({
            filter_loading:true,
            is_filter:true,
            userfilterdata:requestdata
        })
        
    }
    handleContentLoad = (requestdata) => {
       
        this.setState({
            filter_loading:false,
            is_filter:false
        })
        
    }
    render() {
        const content=this.props.news_content.content;
         
        // const category = this.props.news_content.category;

        // const category = this.props.news_content.category;
        const {page_data}=this.props.page_content.content;


        // console.log('page_data=',page_data);

        // console.log('content news=',content);

        return (
        
        <Fragment>

            {page_data && <Fragment>

            {page_data && <Fragment>

            <section class="inner-header">
                <img src={page_data.image ? image_url + page_data.image : `${host_name}img/innerbaner.png`} alt=""></img>
                <h1>{page_data.title}</h1>
                <div class="inner-breadcrumb">
                    <ul class="nav justify-content-center">
                        <li><Link to={{ pathname: `/home` }} >Home</Link></li>
                        <li>{page_data.title} </li>
                    </ul>
                </div>
                <div class="overlay"></div>
            </section>

            </Fragment> }

            

            <div class="tab tab--7 mb-5 news">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tab_nav7">
                                
                            
                                <ul class="nav justify-content-center" id="tab7" role="tablist">

                                { content.length > 0 ?
                                    content.map((row,key) => { 
                                    return(  
                                        
                                        <Fragment>
                                        
                                            <li class="nav-item">
                                                <a  className={`nav-link ${key == 0 ? 'active' : ''}`} id={`tab7_nav${key}`} data-toggle="tab" href={`#tab7_content${key}`} role="tab" aria-controls={`#tab7_nav${key}`}  aria-selected="true">{row.category_name}</a>
                                            </li>
                                        </Fragment>
                                        

                                    )
                                        
                                    })
                                    :
                                    ''
                                }
                                
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tab-content tab--content7" id="tabcontent7">

                                { content.length > 0 ?
                                content.map((row,key) => { 
                                    return(  
                                        
                                        
                                                
                                        <Fragment>
                                                <div class="tab-pane fade show active" id={`tab7_content${key}`}  role="tabpanel" aria-labelledby={`tab7_nav${key}`} >
                                                    <div class="row">

                                                        {row.WebContent.length ? <Fragment>
                                                        {row.WebContent.map((prow, pkey) => {
                                                            return (

                                                                <div class="col-md-4">
                                                                    <div class="news-single">
                                                                        <img src={image_url+''+prow.image} alt=""></img>
                                                                        <h4>
                                                                            <Link to={{pathname:`news${prow.slug}`}} >{prow.title ? prow.title : ''}</Link>
                                                                        </h4>
                                                                        <div class="row">
                                                                            <div class="col-md-7">
                                                                                <p>{prow.publish ? prow.publish : ''}</p>
                                                                            </div>
                                                                            <div class="col-md-5 text-right">
                                                                                <p>{row.category_name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <hr></hr>
                                                                        <p>
                                                                            {prow.short_description.substr(0, 140)}
                                                                            
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            )
                                                        })}
                                                        </Fragment> : ''}

                                                    </div>
                                                </div>
            
                                                
                                        
                                    
                                        </Fragment>
                                            

                                    )
                                
                                })
                                :
                                ''
                                }
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            </Fragment> }
        
        </Fragment>  
        );
        
    }
}

const mapStateToProps = state => ({
    page_content:state.contents.page_content,
    news_content:state.contents.news_content,
    utils:state.utils,
    header:state.header,
    contents:state.contents,
    footer:state.footer,
    layout_id:state.utils.layout_id,
    routemenu:state.utils.routemenu,
    setting:state.utils.setting,
    
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
    setPathLayout:(requestData) =>dispatch(setPathLayout(requestData)),
    PageContent: (requestData) => dispatch(PageContent(requestData)),
    NewsContent: (requestData) => dispatch(NewsContent(requestData)),
   

    
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(WebContent));