import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter,Link } from "react-router-dom";
import { getSetting, homeLayoutContent,PageContent, setPathLayout,getContactusModeSetting } from "../actions/Utils";
import '../App.css';
import ContactUsForm from '../components/contactus/ContactUsForm';
// import { image_url, host_name } from '../../config/Api';
import { image_url, host_name } from '../config/Api';





class Contactus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '',
      header: {},
      container: {},
      page_content:{},
      footer: {},
      layout_id: '',
      contactlayout: null,
      contents_data: {}
    };
  }
  componentDidMount = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const { location } = this.props.history;
    var { pathname } = location
    const { path } = this.props.match;

    const slugpage = this.props.match.path.replace('/','');
    this.props.PageContent(slugpage).then((res)=>{

    });

    this.props.getContactusModeSetting({ 'slug': path }).then((data) => {
      console.log("pageSectionContent =", data);
      this.setState({
        contents_data: data,
        slug: path,
        
      })
    });
    

  }

  setPathLayout = (parms) => {
    this.props.setPathLayout(parms);
  }
  getSetting = async () => {
    let data = await this.props.getSetting();
  }
  static getDerivedStateFromProps(props) {
    return {
      path: props.utils.path,
      header: props.header,
      container: props.container,
      footer: props.footer,
      layout_id: props.utils.layout_id,
      contactlayout: props.contactuslayout
    };
  }
  getContentData = (menu_id) => {

    const requestData = {
      menu_id: menu_id,
    }
    this.props.sectionandContent(requestData);
  }

  render() {

    //  console.log("this contactuslayout = ", this.props.contactuslayout);
    const {contents_data} = this.state;

    const {page_data}=this.props.page_content.content;
   




    return (
      <Fragment>

        
      
      {page_data && <Fragment>
      {page_data && <Fragment>

      <section class="inner-header">
          <img src={page_data.image ? image_url + page_data.image : `${host_name}img/innerbaner.png`} alt=""></img>
          <h1>{page_data.title}</h1>
          <div class="inner-breadcrumb">
              <ul class="nav justify-content-center">
                  <li><Link to={{ pathname: `/home` }} >Home</Link></li>
                  <li>{page_data.title} </li>
              </ul>
          </div>
          <div class="overlay"></div>
      </section>

      </Fragment> }

      <section class="invest-now mb-5">
        <div class="container">
        
        {Object.keys(contents_data).length ? <ContactUsForm data={contents_data} /> : ''}
          
        </div>
      </section>

      </Fragment> }

      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  page_content:state.contents.page_content,
  contactuslayout: state.contents.contactus_layout,
  utils: state.utils,
  header: state.header,
  contents: state.contents,
  footer: state.footer,
  layout_id: state.utils.layout_id,
  routemenu: state.utils.routemenu,
  setting: state.utils.setting,

})

const mapDispatchToProps = dispatch => ({
  getSetting: () => dispatch(getSetting()),
  setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
  PageContent: (requestData) => dispatch(PageContent(requestData)),
  homeLayoutContent: (requestData) => dispatch(homeLayoutContent(requestData)),
  getContactusModeSetting: (requestData) => dispatch(getContactusModeSetting(requestData)),
  
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contactus));