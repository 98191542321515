import axios from 'axios';

var token = localStorage.getItem("token")?'Token '+localStorage.getItem("token"):'';
// export default axios.create({
//   // baseURL: `http://jsonplaceholder.typicode.com/`
//   baseURL: `http://127.0.0.1:8000/`,
//   headers: {'Authorization': token}
// });

const axiosInstance = axios.create();

// axiosInstance.defaults.baseURL = 'http://localhost/licbangladesh_admin/api/';
// axiosInstance.defaults.baseURL = 'http://192.168.10.116/licbangladesh_admin/api/';
// axiosInstance.defaults.baseURL = 'https://new.bdventure.com/bdventureadmin/api/';
axiosInstance.defaults.baseURL = 'https://bdventure.com/bdventureadmin/api/';

export const casInstance = axios.create();
casInstance.defaults.baseURL = 'https://bdventure.com/bdventureadmin/api/';
export const caversion = 'v1/';
casInstance.defaults.headers.common['Authorization'] = 'Basic Y2xpZW50LXdlYjpVQTAxMjM0NTY=';

// axiosInstance.defaults.headers.common['Authorization'] = token;
// axiosInstance.defaults.headers.common['User-ID'] = '';

export const version = 'v1/';
export const imagePath = 'v1/';


export const processMaker = axios.create();
processMaker.defaults.baseURL = 'https://bdventure.com/bdventureadmin/api/';
processMaker.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// export const image_url = 'http://192.168.10.116/licbangladesh_admin/';
export const image_url = 'https://bdventure.com/bdventureadmin/';
// export const image_url = 'http://localhost/licbangladesh_admin/';




export const base_path = '/';
// export const base_path = 'https://licbangladesh.com/dynamic/';
// export const host_name = 'https://licbangladesh.com/dynamic/';
export const host_name = 'https://bdventure.com/';
// export const host_name = 'http://localhost:3000/';


export const encryption_salt="Bdventure@2022";
export const google_recaptcha_site_key = '6Le2E5gaAAAAADPCWtOtzWkaBwQ1jAlPtjP7XRuK';

export default axiosInstance;

 

export const axiosPorichoy = axios.create();
axiosPorichoy.defaults.baseURL = 'https://bdventure.com/bdventureadmin/api/';
// axiosPorichoy.defaults.baseURL = 'https://api.porichoybd.com/sandbox-api/';
axiosPorichoy.defaults.headers.common['x-api-key'] = '92673172-a9e7-4d92-9324-5f3821927fb0';
axiosPorichoy.defaults.headers.common['Content-Type'] = 'application/json';